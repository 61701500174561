<script setup>
    import { Head } from "@inertiajs/vue3"
    import logotype from "/public/img/opstimus-prime-logo.png"
    import { useStatusMessage, useStatusName } from "@/Composables/Status"

    const props = defineProps({ status: Number })
</script>

<template>
    <Head>
        <title>{{ useStatusName(status) }}</title>
    </Head>

    <div class="h-screen flex justify-center">
        <div class="flex items-center space-x-10">
            <img
                class="h-20 w-20 mr-4"
                v-bind:src="logotype"
            />

            <div class="flex flex-col items-start space-y-2 font-mono text-primary-black">
                <h1
                    class="text-2xl text-primary-blue"
                    v-text="`${props.status} - ${useStatusName(status)}`"
                />

                <p
                    class="text-md"
                    v-text="useStatusMessage(status)"
                />
            </div>
        </div>
    </div>
</template>
